import { WorldLayer } from "../world_layer"
import { Debounce } from "../../../../appos/lib/debounce"

export class IslandLocations extends WorldLayer {
  init() {
    this.dependsOnIslands()
    this.enableInitially = false
    this.debounceRenderWithFade = 200
    this.afterOChange = "render"
    this.throttledLoader = new Debounce({ name: "isldataLoad", debounce: 750, throttle: 2000 }, _ => this.autoload())
    this.debouncedUpdate = new Debounce({ name: "isllocUpdate", debounce: 500 }, _ => {
      this.update()
    })
    this.isldata = new IslandLocationData(this, this.world.world.data("fetchLocationData"))
    this.debug = new DebugMarkers(this)

    // forceLoadAll (load&render at zero && autoload)
    // map & icon
    //   * cooking spots
    //   * barrels / lootable inventories
    //   * glitterbeard books
    //   * interactables
    //   * ???

    this.addC("landmark_treasure", true, { shape: "circle", fill: "#DA70D662", loadAt: 15, renderAt: 10 })
    this.addC("sunken", false, { color: "blue", shape: "x", loadAt: 20, renderAt: 15 })
    this.addC("burried", false, { color: "#7B3F00", loadAt: 20, renderAt: 15 })
    this.addC("underground", false, { color: "#5C4033", loadAt: 20, renderAt: 15 })
    this.addC("ai_spawn", false, { color: "orange", loadAt: 20, renderAt: 15 })
    this.addC("washed_up", true, { shape: "chest", fill: "#aa00ff", stroke: "#111", loadAt: 7, renderAt: 5 })
    this.addC("lore_book_items_data_asset", true, { shape: "book", fill: "#992200", stroke: "#111", loadAt: 0, renderAt: 0 })
  }

  ready() {
    this.bookIconText = this.createText("\uF193", { anchor: -0.5, font: "40px bootstrap-icons", stroke: "#111", lw: 2, strokeAfterFill: false })
    this.chestIconText = this.createText("\uF182", { anchor: -0.5, font: "40px bootstrap-icons", stroke: "#111", lw: 2, strokeAfterFill: false })
  }

  updateZoom(ev) {
    this.bookIconText.opts.font = `${Math.max(26, 300 / this.world.realScale)}px bootstrap-icons`
    this.chestIconText.opts.font = `${Math.max(20, 300 / this.world.realScale)}px bootstrap-icons`
    this.throttledLoader.call()
  }

  onEnable() {
    this.throttledLoader.callNow()
  }

  onDisable() {
    this.clear()
  }

  updatePan(ev) {
    this.throttledLoader.call()
  }

  autoload() {
    if(!this.enabled) return
    // load visible islands
    this.world.islands.fullyInView.forEach(isl => this.isldata.load(isl))
  }

  addC(type, doRender = false, opts = {}) {
    this.isldata.register(type, doRender, opts.loadAt, opts.renderAt)
    this.optreg.add("bool", `${type}.render`, doRender).onChange(v => this.togglePart(type, v))
    this.optreg.add("str", `${type}.color`, opts.color ?? (opts.shape == "circle" ? "none" : "#ff00ff"))
    this.optreg.add("str", `${type}.fill`, opts.fill ?? "none")
    this.optreg.add("str", `${type}.shape`, opts.shape ?? "+")
    this.optreg.add("int", `${type}.dim`, opts.dim ?? (opts.shape == "circle" ? 300 : 10000))
    this.optreg.add("float", `${type}.loadAt`, opts.loadAt ?? 0)
    this.optreg.add("float", `${type}.renderAt`, opts.renderAt ?? 0)
    this.optreg.add("bool", `${type}.scaled`, opts.dim ?? opts.shape != "circle")
  }

  togglePart(part, toggle) {
    if(toggle) {
      this.isldata.parts.set(part, true)
      this.autoload()
    } else {
      this.isldata.parts.set(part, false)
    }
  }

  render() {
    if(!this.enabled) return false
    this.clear()

    this.isldata.idata.forEach((idata, islid) => {
      this.renderIslandFragment(islid, idata)
    })

    this.debug.render()
    this.isldata.updateUiLoading(true)
  }

  renderIslandFragment(islid, idata, force = false) {
    const vscale = this.world.vscale
    idata ??= this.isldata.idata.get(islid)
    this.isldata.parts.forEach((doRender, part) => {
      if(!doRender) return false
      const lc = this.o(part)
      if(!force && vscale < lc.get("renderAt")) {
        return false
      }
      const p = idata.parts.get(part)
      if(!p || !p.loaded) return false
      this._renderPart(part, p.data)
    })
  }

  _renderPart(ltype, ldat, lc) {
    lc ??= this.o(ltype)

    if(!lc || !lc.get("render")) return false
    if(!(lc.get("fill") ?? lc.get("color"))) {
      console.warn("no color for ltype", ltype, "ignoring entries")
      return false
    }
    const shape = lc.get("shape")
    const dim = lc.get("dim")
    const scaled = lc.get("scaled")

    this.trx(ctx => {
      const textToRender = []
      this.applyCtxOpts(lc)

      if(ltype == "landmark_treasure") {
        Object.entries(ldat).forEach(([lmname, coords]) => {
          ctx.fillStyle = coords.color + 59
          let zsum = 0
          coords.forEach((p, i) => {
            zsum += p[2]
            this._drawMarker(p, shape, dim, scaled, lc)
          })

          // name + asl
          if(coords.length) {
            coords.textEl ??= this.createText(`${lmname.split("@")[0]} (${((zsum / coords.length) / 100).toFixed(0)}m)`, { anchorX: -0.5, font: "20px Arial", lw: 3, stroke: "black", color: coords.color })
            coords.textEl.updatePos(...coords[0])
            textToRender.push(coords.textEl)
          }
        })
      } else {
        ldat.forEach(p => {
          this._drawMarker(p, shape, dim, scaled, lc)
        })
      }

      textToRender.forEach(t => t.draw())
    })
  }

  _drawMarker(p, type, dim = 10000, scaled = true, opts = {}, mayBare = true) {
    if(type == "+") {
      this.drawX(p.slice(0, 2), dim, scaled, opts, mayBare)
    } else if (type == "x") {
      this.drawX45(p.slice(0, 2), dim, scaled, opts, mayBare)
    } else if (type == "circle") {
      // opts.scaled ??= scaled
      this.drawCircle(p.slice(0, 2), dim, opts, mayBare)
    } else if (type == "chest") {
      this.chestIconText.draw(...p.slice(0, 2))
    } else if (type == "book") {
      this.bookIconText.draw(...p.slice(0, 2))
      if(this.world.vscale >= 30) {
        this.createText(`↑${(p[2] / 100).toFixed(0)}m`, { offsetY: 0, anchor: [-0.5, -1.7], lw: 3, color: "#111", stroke: "#992200", font: `20px sans-serif` }).draw(p[0], p[1] - this.bookIconText.boundingBox[3])
      }
      if(this.world.vscale >= 250) {
        this.drawX(p)
      }
    } else {
      alert(`unknown type ${type}`)
    }
  }

  rotateCoordinates(p, angleDegrees) {
    // Convert angle from degrees to radians
    const angleRadians = angleDegrees * (Math.PI / 180)
    // console.log(angleDegrees, angleRadians, p)
    const cos = Math.cos(angleRadians)
    const sin = Math.sin(angleRadians)

    return [
      p[0] * cos - p[1] * sin,
      p[0] * sin + p[1] * cos,
      p[2],
    ]
  }
}

class IslandLocationData {
  constructor(parent, url) {
    this.parent = parent
    this.notifyTargets = [parent]
    this.url = url
    this.idata = new Map()
    this.parts = new Map()

    this.numLoading = 0
    this.maxLoadingParallel = 16
    this.loadingQueue = []
    this.ui_hud_spinner = this.parent.world.worldParentEl.querySelector(`[data-hudval="ilocLoading"]`)
    this.ui_hud_num = this.parent.world.worldParentEl.querySelector(`[data-hudval="ilocLoadingNum"]`)
  }

  register(part, enabled = true) {
    this.parts.set(part, enabled)
  }

  enabledParts() {
    return Array.from(this.parts.entries()).filter(([k, v]) => v).map(([k]) => k)
  }

  dequeueLoad() {
    if(!this.loadingQueue.length) return false
    if(this.numLoading >= this.maxLoadingParallel) return false

    const [isl, idat] = this.loadingQueue.shift()
    this.numLoading += 1
    this.updateUiLoading()

    idat.promise = this.fetchPartDat(isl, idat).finally(_ => {
      this.numLoading -= 1
      this.dequeueLoad()
      this.updateUiLoading()
      this.notifyTargets.forEach(nt => nt.debouncedUpdate.call())
    })
  }

  updateUiLoading(spinner = false) {
    this.ui_hud_num.innerHTML = this.loadingQueue.length
    this.ui_hud_spinner.classList.toggle("d-none", this.loadingQueue.length === 0 && spinner)
  }

  enqueueLoad(payload) {
    this.loadingQueue.push(payload)
    this.dequeueLoad()
  }

  get available() { return !!this.url }

  load(isl, parts = null, force = false) {
    const vscale = this.parent.world.vscale
    parts ??= this.enabledParts()
    if(!this.url) return false

    if(!this.idata.has(isl.id)) {
      this.idata.set(isl.id, { parts: new Map() })
    }
    const isldata = this.idata.get(isl.id)
    const promises = []

    parts.forEach(part => {
      const lc = this.parent.o(part)
      if(!isl.mappedFeatures.includes(part) || (!force && vscale < lc.get("loadAt"))) {
        return false
      }

      if(!isldata.parts.has(part)) {
        const idat = {
          part: part,
          loading: true,
          loaded: false,
          failed: false,
          data: null,
        }
        this.enqueueLoad([isl, idat])
        isldata.parts.set(part, idat)
      }
      promises.push(isldata.parts.get(part).promise)
    })

    return Promise.all(promises)
  }

  fetchPartDat(isl, idat) {
    return fetch(`${this.url}${this.url.includes("?") ? "&" : "?"}ilocs=${isl.id}&part=${idat.part}`).then(r => r.ok ? r.json() : Promise.reject(r)).then(j => {
      idat.loading = false
      idat.loaded = true
      idat.data = this.postprocess(j, isl, idat)
    }).catch(e => {
      idat.loading = false
      idat.failed = e.status !== 404
      if(idat.failed) {
        this.parent.toastNotification(`Failed to load island location data: ${e.status ?? ""} ${e.statusText ?? ""} ${e.message ?? ""}`)
        console.error("failed to load isldata for ", isl.id, isl, idat, e)
      }
    })
  }

  postprocess(data, isl, idat) {
    const ipos = isl.position ?? [0, 0]
    const rdelta = -90 + isl.rotation
    const cpos = isl.cameraPosition
    if(!rdelta && !cpos) return data

    // offset by camera position & rotate if necessary
    if(idat.part == "landmark_treasure") {
      const list = Object.entries(data)
      list.forEach(([lmname, coords], i) => {
        data[lmname] = coords.map(p => {
          if(cpos) p = [p[0] - cpos[0], p[1] - cpos[1], p[2]]
          if(rdelta) p = this.parent.rotateCoordinates(p, rdelta)
          p[0] += ipos[0]
          p[1] += ipos[1]
          return p
        })
        data[lmname].color = this.parent.rgbToHex(...this.parent.hsvToRgb((i/list.length) * 0.9, 1, 1))
      })
      return data
    } else {
      return data.map(p => {
        const z = p[2]
        if(cpos) p = [p[0] - cpos[0], p[1] - cpos[1], 0]
        if(rdelta) p = this.parent.rotateCoordinates(p, rdelta)
        p[0] += ipos[0]
        p[1] += ipos[1]
        p[2] = z
        return p
      })
    }
  }
}

class DebugMarkers {
  constructor(layer) {
    this.layer = layer
    this.markers = new Map()
  }

  clearAll() {
    this.markers.clear()
  }

  clear(islid) {
    this.markers.delete(islid)
  }

  render() {
    this.markers.forEach((markers, islid) => {
      const isl = this.layer.world.islands.find(islid)
      const rdelta = -90 + isl.rotation
      const cpos = isl.cameraPosition

      markers.forEach(m => {
        let [x, y] = m.pos
        if(cpos) {
          x = x - cpos[0]
          y = y - cpos[1]
        }
        if(!m.globalOrigin && m.rotateOrigin && rdelta) [x, y] = this.layer.rotateCoordinates([x, y], rdelta)
        // if(m.globalOrigin) [x, y] = this.layer.rotateCoordinates([x, y], -90)
        if(!m.globalOrigin) {
          x += isl.position[0]
          y += isl.position[1]
        }

        this.layer._drawMarker([x, y], m.shape, m.dim, m.scaled, m.ctx, false)

        if(m.label) {
          this.layer.createText(`${m.label}`, { offsetY: -16, anchor: [-0.5, -1], font: `8px sans-serif` }).draw(x, y)
        }
      })
    })
  }

  replace(islid, ...args) {
    this.markers.delete(islid)
    this.add(islid, ...args)
  }

  add(islid, markers, opts = {}) {
    if(Array.isArray(markers[0])) {
      markers.forEach(([x, y]) => {
        this.marker(islid, x, y, opts, false)
      })
    } else {
      markers.forEach(md => {
        const mdo = Object.assign({}, opts, md.o ?? {})
        if(md.n) { mdo.label = md.n }
        if(md.r) { mdo.dim = md.r }

        md.p.forEach(([x, y]) => {
          this.marker(islid, x, y, mdo, false)
        })
      })
    }
    this.layer.debouncedUpdate.call()
  }

  marker(islid, x, y, opts = {}, updateCall = true) {
    opts = {...opts}
    opts.pos = [x, y]
    opts.dim ??= 300
    opts.shape ??= "circle"
    opts.scaled ??= false
    opts.rotateOrigin ??= true
    opts.globalOrigin ??= false
    opts.ctx ??= {}
    opts.ctx.fill ??= "red"
    opts.ctx.color ??= "red"
    opts.ctx.globalAlpha ??= 0.5

    if(!this.markers.has(islid)) this.markers.set(islid, new Set())
    this.markers.get(islid).add(opts)
    if(updateCall) this.layer.debouncedUpdate.call()
  }
}
