import { WorldLayer } from "../world_layer"
import { Debounce } from "../../../../appos/lib/debounce"

export class IslandRender extends WorldLayer {
  init() {
    this.enableInitially = true
    // this.debounceRenderAsync = 0

    this.throttledShadowUpdate = new Debounce({ name: "shadowRender", debounce: 500 }, _ => this.render())

    this.islandsReady = false
    this.islands.oneIslandEverInView.then(_ => {
      this.islandsReady = true
      this.update()
    })
  }

  get islands() { return this.world.islands }
  find(...args) { return this.islands.find(...args) }

  update(ev) {
    super.update(ev)
  }

  clearIslandFragment(isl, world_size) {
    if(!world_size) {
      const res = isl.activeResolution
      world_size = [res.w, res.w]
    }
    const padded = [
      world_size[0] * Math.sqrt(2),
      world_size[1] * Math.sqrt(2),
    ]
    this.clearRect(this.translateOnPlane(isl.position, padded), ...padded)
    // this.drawRect(this.translateOnPlane(isl.position, padded), ...padded, { fill: "red" })
    //this.drawRect(this.translateOnPlane(isl.position, world_size), ...world_size, { fill: "green" })
    //this.drawImage(res.img, tloc, ...world_size, { rotate: isl.rotation, translatePosition: true, clearBox: clearBox })
  }

  renderIslandFragment(isl, clearBox = true, enableShadows = true) {
    this.trx(ctx => {
      const res = isl.activeResolution

      if(!res.img.complete) {
        this.drawX45(isl.position)
        // console.log("not loaded yet", res.img)
        return false
      }

      const world_size = [res.w, res.w]
      let ipos = isl.position.slice()
      if(isl.id == "wld_feature_tribute_peak" && isl.resolution == "high") {
        ipos[0] -= 4500
      }
      const tloc = this.translateOnPlane(ipos, world_size)
      this.clearIslandFragment(isl, world_size)

      if(enableShadows && isl.hasDropshadow && this.islands.o("shadows")) {
        ctx.shadowColor = '#222'
        const ii = this.clamp(this.zoomAlpha(2, 18, "linear", true), 0.1)
        ctx.shadowBlur = ii * 50
      }
      this.drawImage(res.img, tloc, ...world_size, { rotate: isl.rotation, translatePosition: true, clearBox: clearBox })
    })
  }

  render() {
    if(!this.enabled) return
    if(!this.islandsReady) return
    this.clear()

    // shadows too expensive when panning
    const panning = this.world.mouseMoveDelta[0] != null
    if(panning) this.throttledShadowUpdate.call()

    this.islands.filter(isl => isl.inView && isl.renderer == "canvas").forEach(isl => {
      this.renderIslandFragment(isl, false, !panning)
    })
  }
}
